import {
    DataService,
    ModalService,
    RelationProductVariantInputComponent,
    SharedModule,
} from '@vendure/admin-ui/core';
import {
    GetProductVariantDocument,
    GetProductVariantListSimpleDocument,
    GetProductVariantListSimpleQuery,
    GetProductVariantQuery,
} from '../../generated-types';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { DeliverType } from '../../ui-types';

@Component({
    selector: 'filtered-relation-product-variant-input',
    templateUrl: './filtered-relation-product-variant-input.component.html',
    styleUrls: ['./filtered-relation-product-variant-input.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FilteredRelationProductVariantInputComponent
    extends RelationProductVariantInputComponent
    implements OnInit
{
    @Input() deliverDevice: DeliverType;
    results$: Observable<GetProductVariantListSimpleQuery['productVariants']['items']>;
    productVariant$: Observable<GetProductVariantQuery['productVariant'] | undefined>;
    constructor(
        protected _modalService: ModalService,
        protected _dataService: DataService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        super(_modalService, _dataService);
    }

    ngOnInit() {
        this.productVariant$ = this.parentFormControl.valueChanges.pipe(
            startWith(this.parentFormControl.value),
            map(variant => variant?.id),
            distinctUntilChanged(),
            switchMap(id => {
                if (id) {
                    return this._dataService
                        .query(GetProductVariantDocument, {
                            id,
                        })
                        .mapStream(data => data.productVariant || undefined);
                } else {
                    return of(undefined);
                }
            }),
        );
        this.productVariant$.subscribe(() => {
            this._changeDetectorRef.detectChanges();
        });

        this.results$ = this.searchTerm$.pipe(
            debounceTime(200),
            switchMap(term =>
                this._dataService
                    .query(GetProductVariantListSimpleDocument, {
                        options: {
                            ...(term
                                ? {
                                      filter: {
                                          name: {
                                              contains: term,
                                          },
                                          deliverDevice: {
                                              eq: this.deliverDevice,
                                          },
                                      },
                                  }
                                : {
                                      filter: {
                                          deliverDevice: {
                                              eq: this.deliverDevice,
                                          },
                                      },
                                  }),
                            take: 10,
                        },
                    })
                    .mapSingle(data => data.productVariants.items),
            ),
        );
    }
}
