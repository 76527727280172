import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderExportNavModule } from './extensions/b1191a9cf32c3f8f0ce943df6fadeb619a20063cfbb83cb713a28589234c27c9/order-export-nav.module';
import { MetricsWidgetSharedModule } from './extensions/c37f7ecf749d21d3b785fd4607df1f17901e191695fd547a9af92f65635c0a0c/metrics-widget.shared-module';

import SharedProviders_0_0 from './extensions/9253f502e27d351cbb11a6d33a3c4805a749178c7562aec5e97650cfdc168234/providers';
import SharedProviders_1_0 from './extensions/78028ce91f3bfe7493ef6d19678d8770016cfbde265e8e7646359826aacd9f29/providers';
import SharedProviders_4_0 from './extensions/5816c87a45510c03f50b352ba98134a51e4cc1eec716b9cec4c47ec0cefd0f67/providers';
import SharedProviders_5_0 from './extensions/b99278b3b23aa6f27b117069185cf86995d09434ae4d507281e721d06b0f7a06/providers';
import SharedProviders_6_0 from './extensions/a9c27aa9b33372d48339b8eb4f3fbf6f4aae39a2b6176d53438bafa8671c191c/providers';


@NgModule({
    imports: [CommonModule, OrderExportNavModule, MetricsWidgetSharedModule],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_4_0, ...SharedProviders_5_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
