<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-left>
            <div class="agromaat-btn-group agromaat-btn-outline-primary mode-select">
                <div *ngFor="let option of modeOptions" class="radio agromaat-btn">
                    <input
                        type="radio"
                        name="mode"
                        [id]="option.id"
                        [title]="option.title"
                        [value]="option.value"
                        [checked]="mode === option.value"
                    />
                    <label [for]="option.id" (click)="setMode(option.value)">
                        <cds-icon
                            *ngIf="option.iconShape === 'arrow'"
                            [attr.direction]="option.iconDirection"
                            [attr.shape]="option.iconShape"
                        ></cds-icon>
                        <clr-icon
                            *ngIf="option.iconShape !== 'arrow'"
                            [attr.shape]="option.iconShape"
                        ></clr-icon>
                    </label>
                </div>
            </div>
        </vdr-ab-left>
        <vdr-ab-right>
            <div class="flex clr-row items-center">
                <vdr-dropdown *ngIf="(machines$ | async)?.length > 1">
                    <button type="button" class="btn button button-select-machine" vdrDropdownTrigger>
                        {{ 'agromaat.common.agrobox' | translate }}
                        {{ (selectedMachine$ | async).id }}
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <vdr-dropdown-menu vdrPosition="bottom-right">
                        <button
                            type="button"
                            class="language-option"
                            *ngFor="let machine of machines$ | async"
                            (click)="selectMachine(machine.id)"
                            vdrDropdownItem
                        >
                            <span>{{ machine.id }}</span>
                        </button>
                    </vdr-dropdown-menu>
                </vdr-dropdown>
            </div>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<div class="grid-container max-w-layout">
    <div class="loading" *ngIf="isLoading">
        <clr-spinner clrInline size="lg"></clr-spinner>
    </div>
    <ng-container *ngFor="let columnItems of selectedCells$ | async">
        <div class="grid-column">
            <ng-container *ngFor="let productCell of columnItems">
                <div
                    class="grid-item grid-item-{{ productCell.type }} grid-item-{{
                        productCell.state | lowercase
                    }} grid-item-mode-{{ mode }}"
                    (click)="mode !== 'home' ? update(productCell) : null"
                >
                    <div
                        class="img-placeholder"
                        *ngIf="productCell.productVariant as productVariant; else noProduct"
                    >
                        <img
                            *ngIf="
                                productVariant.featuredAsset || productVariant.product.featuredAsset as asset;
                                else noImage
                            "
                            [src]="asset | assetPreview: 'thumb'"
                            alt="{{ productCell.id }} - {{ productCell.productVariant.name }}"
                        />
                    </div>
                    <ng-template #noProduct>
                        <div class="fallback-product-div">
                            <p>Nog geen product gekoppeld</p>
                        </div>
                    </ng-template>
                    <ng-template #noImage>
                        <div class="fallback-image-div">
                            <p>
                                {{ productCell.productVariant.name }}
                            </p>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
