import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem(
        {
            id: 'kiosk-devices',
            label: 'agromaat.kiosk.kiosk-devices',
            routerLink: ['/extensions/kiosk-devices'],
            icon: 'tablet',
            requiresPermission: 'SuperAdmin',
        },
        'agromaat',
    ),
];
