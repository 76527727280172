<vdr-page-detail-layout>
    <vdr-page-block>
        <vdr-card>
            <form class="form" [formGroup]="detailForm">
                <section class="form-block">
                    <div class="clr-row">
                        <div class="clr-col-8">
                            <vdr-form-field label="{{ 'catalog.variant' | translate }}" for="productVariant">
                                <filtered-relation-product-variant-input
                                    formControlName="productVariant"
                                    [parentFormControl]="detailForm.get('productVariant')"
                                    [config]="htmlFormInputConfigArgsDef"
                                    deliverDevice="vending"
                                    [readonly]="false"
                                    style="max-width: 100%"
                                />
                            </vdr-form-field>
                        </div>
                        <div class="clr-col-4">
                            <vdr-form-field label="{{ 'order.quantity' | translate }}" for="quantity">
                                <input name="quantity" type="number" min="1" formControlName="quantity" />
                            </vdr-form-field>
                        </div>
                    </div>
                    <div class="clr-row clr-justify-content-end">
                        <div class="clr-col-2">
                            <button
                                [disabled]="!detailForm.valid || !('ManageVendingStock' | hasPermission)"
                                class="btn btn-primary"
                                (click)="fulfillItems()"
                            >
                                {{ 'agromaat.common.fulfill' | translate }}
                            </button>
                        </div>
                    </div>
                </section>
            </form>
        </vdr-card>
    </vdr-page-block>
</vdr-page-detail-layout>
