<vdr-page-block>
    <vdr-action-bar>
        <vdr-ab-left></vdr-ab-left>
        <vdr-ab-right>
            <button
                [disabled]="!('SuperAdmin' | hasPermission)"
                class="btn btn-primary"
                [routerLink]="['./', 'create']"
            >
                <clr-icon shape="plus"></clr-icon>
                Nieuw vak
            </button>
        </vdr-ab-right>
    </vdr-action-bar>
</vdr-page-block>

<vdr-data-table-2
    id="product-cell-list"
    [items]="items$ | async"
    [itemsPerPage]="itemsPerPage$ | async"
    [totalItems]="totalItems$ | async"
    [currentPage]="currentPage$ | async"
    (pageChange)="setPageNumber($event)"
    (itemsPerPageChange)="setItemsPerPage($event)"
    [filters]="filters"
>
    <vdr-bulk-action-menu
        locationId="product-cells-list"
        [hostComponent]="this"
        [selectionManager]="selectionManager"
    />
    <!-- Here we define all the available columns -->
    <vdr-dt2-column [heading]="'common.id' | translate" [hiddenByDefault]="true">
        <ng-template let-productCell="item">{{ productCell.id }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.col' | translate" [sort]="sorts.get('col')">
        <ng-template let-productCell="item">
            {{ productCell.col }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.row' | translate" [sort]="sorts.get('row')">
        <ng-template let-productCell="item">
            <a class="button-ghost" [routerLink]="['./', productCell.id]"
                ><span>{{ productCell.row }}</span
                ><clr-icon shape="arrow right" />
            </a>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.image' | translate" id="image">
        <ng-template let-productCell="item">
            <div class="image-placeholder">
                <img
                    *ngIf="
                        productCell.productVariant.featuredAsset ||
                            productCell.productVariant.product.featuredAsset as asset;
                        else imagePlaceholder
                    "
                    [src]="asset | assetPreview: 'tiny'"
                />
                <ng-template #imagePlaceholder>
                    <div class="placeholder">
                        <clr-icon shape="image" size="48"></clr-icon>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'catalog.variant' | translate">
        <ng-template let-productCell="item">
            {{ productCell.productVariant.name }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.open' | translate" id="open">
        <ng-template let-productCell="item">
            <!-- open button -->
            <button
                *ngIf="productCell.state !== 'OPENED'"
                type="button"
                class="btn btn-link btn-sm"
                (click)="openProductCell(productCell.id)"
            >
                {{ 'agromaat.common.open' | translate }}
            </button>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
        [heading]="'agromaat.common.type' | translate"
        [hiddenByDefault]="true"
        [sort]="sorts.get('col')"
    >
        <ng-template let-productCell="item">
            {{ productCell.type }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.productModule' | translate" [hiddenByDefault]="true">
        <ng-template let-productCell="item">
            {{ productCell.productModule.name }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'agromaat.common.state' | translate">
        <ng-template let-productCell="item">
            <clr-icon *ngIf="productCell.state === 'EMPTY'" shape="times" class="is-warning"></clr-icon>
            <clr-icon *ngIf="productCell.state === 'FILLED'" shape="check" class="is-success"></clr-icon>
            {{ 'state.' + productCell.state | translate }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [heading]="'common.enabled' | translate" id="enabled">
        <ng-template let-productCell="item">
            <vdr-chip *ngIf="productCell.enabled" colorType="success">{{
                'common.enabled' | translate
            }}</vdr-chip>
            <vdr-chip *ngIf="!productCell.enabled" colorType="warning">{{
                'common.disabled' | translate
            }}</vdr-chip>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column [hiddenByDefault]="!('SuperAdmin' | hasPermission)">
        <ng-template let-productCell="item">
            <vdr-dropdown>
                <button
                    type="button"
                    class="btn btn-link btn-sm"
                    vdrDropdownTrigger
                    [disabled]="!('SuperAdmin' | hasPermission)"
                >
                    {{ 'agromaat.common.actions' | translate }}
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <vdr-dropdown-menu vdrPosition="bottom-right">
                    <button
                        type="button"
                        class="delete-button"
                        (click)="deleteProductCell(productCell.id)"
                        vdrDropdownItem
                    >
                        <clr-icon shape="trash" class="is-danger"></clr-icon>
                        {{ 'common.delete' | translate }}
                    </button>
                </vdr-dropdown-menu>
            </vdr-dropdown>
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
        [heading]="'common.created-at' | translate"
        id="created-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('createdAt')"
    >
        <ng-template let-productCell="item">
            {{ productCell.createdAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
        [heading]="'common.updated-at' | translate"
        id="updated-at"
        [hiddenByDefault]="true"
        [sort]="sorts.get('updatedAt')"
    >
        <ng-template let-productCell="item">
            {{ productCell.updatedAt | localeDate: 'short' }}
        </ng-template>
    </vdr-dt2-column>
</vdr-data-table-2>
