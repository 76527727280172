import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationService, SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';

import {
    DeleteProductCellDocument,
    DeleteProductCellInput,
    GetProductCellsDocument,
    OpenProductCellDocument,
    OpenProductCellInput,
    ProductCellState,
} from '../../generated-types';

import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'product-cells-list',
    templateUrl: './product-cells-list.component.html',
    styleUrls: ['./product-cells-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductCellsListComponent
    extends TypedBaseListComponent<typeof GetProductCellsDocument, 'productCells'>
    implements OnInit
{
    readonly filters = this.createFilterCollection()
        .addFilter({
            name: 'col',
            type: { kind: 'number' },
            label: 'agromaat.common.col',
            filterField: 'col',
        })
        .addFilter({
            name: 'row',
            type: { kind: 'number' },
            label: 'agromaat.common.row',
            filterField: 'row',
        })
        .addFilter({
            name: 'state',
            type: {
                kind: 'select',
                options: [
                    {
                        label: 'state.EMPTY',
                        value: ProductCellState.EMPTY,
                    },
                    {
                        label: 'state.OPENED',
                        value: ProductCellState.OPENED,
                    },
                    {
                        label: 'state.FILLED',
                        value: ProductCellState.FILLED,
                    },
                ],
            },
            label: 'agromaat.common.state',
            filterField: 'state',
        })
        .addFilter({
            name: 'enabled',
            type: {
                kind: 'boolean',
            },
            label: 'common.enabled',
            filterField: 'enabled',
        })
        .addDateFilters()
        .connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .defaultSort('createdAt', 'DESC')
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'col' })
        .addSort({ name: 'row' })
        .connectToRoute(this.route);
    itemsPerPage$ = new BehaviorSubject(50);

    constructor(private readonly notificationService: NotificationService) {
        super();
        super.configure({
            document: GetProductCellsDocument,
            getItems: data => data.productCells,
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    deleteProductCell(id: string): void {
        const input: DeleteProductCellInput = {
            id,
        };
        this.dataService.mutate(DeleteProductCellDocument, { input }).subscribe(
            () => {
                this.notificationService.success('common.notify-delete-success', {
                    entity: 'Productvak',
                });
                this.refresh();
            },
            () => {
                this.notificationService.error('common.notify-delete-error', {
                    entity: 'Productvak',
                });
            },
        );
    }

    openProductCell(id: string): void {
        const input: OpenProductCellInput = {
            id,
        };
        this.dataService.mutate(OpenProductCellDocument, { input }).subscribe(
            () => {
                this.notificationService.success('agromaat.settings.open-product-cell-success', {
                    entity: 'Productvak',
                });
                this.refresh();
            },
            () => {
                this.notificationService.error('agromaat.settings.open-product-cell-error', {
                    entity: 'Productvak',
                });
            },
        );
    }
}
