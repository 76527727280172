export const extensionRoutes = [  {
    path: 'extensions/agromaat-stock',
    loadChildren: () => import('./extensions/9253f502e27d351cbb11a6d33a3c4805a749178c7562aec5e97650cfdc168234/routes'),
  },
  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/b1191a9cf32c3f8f0ce943df6fadeb619a20063cfbb83cb713a28589234c27c9/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/order-plugin',
    loadChildren: () => import('./extensions/5816c87a45510c03f50b352ba98134a51e4cc1eec716b9cec4c47ec0cefd0f67/routes'),
  },
  {
    path: 'extensions/kiosk-devices',
    loadChildren: () => import('./extensions/b99278b3b23aa6f27b117069185cf86995d09434ae4d507281e721d06b0f7a06/routes'),
  }];
