import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule, TypedBaseListComponent } from '@vendure/admin-ui/core';
import { GetProductModulesDocument } from '../../generated-types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'product-modules-overview',
    templateUrl: './product-modules-overview.component.html',
    styleUrls: ['./product-modules-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductModulesOverviewComponent extends TypedBaseListComponent<
    typeof GetProductModulesDocument,
    'productModules'
> {
    itemsPerPage$ = new BehaviorSubject(50);
    readonly filters = this.createFilterCollection()
        .addIdFilter()
        .addFilters([
            {
                name: 'connectivityStatus',
                type: {
                    kind: 'select',
                    options: ['ONLINE', 'OFFLINE'].map(value => ({
                        value,
                        label: value,
                    })),
                },
                label: 'agromaat.common.connectivityStatus',
                filterField: 'connectivityStatus',
            },
            {
                name: 'serialNumber',
                type: {
                    kind: 'text',
                },
                label: 'agromaat.common.serialNumber',
                filterField: 'serialNumber',
            },
        ])
        .connectToRoute(this.route);

    readonly sorts = this.createSortCollection()
        .defaultSort('createdAt', 'DESC')
        .addSort({ name: 'createdAt' })
        .addSort({ name: 'updatedAt' })
        .addSort({ name: 'connectivityStatus' })
        .addSort({ name: 'serialNumber' })
        .addSort({ name: 'id' })
        .addSort({ name: 'lastConnectivityUpdate' })
        .addSort({ name: 'type' })
        .connectToRoute(this.route);

    constructor() {
        super();
        super.configure({
            document: GetProductModulesDocument,
            getItems: data => {
                const items = data.productModules.items.map(item => ({
                    ...item,
                    type: this.getModuleType(item.__typename),
                }));
                return {
                    items,
                    totalItems: data.productModules.totalItems,
                };
            },
            setVariables: (skip, take) => ({
                options: {
                    skip,
                    take,
                    filter: {
                        ...this.filters.createFilterInput(),
                    },
                    sort: this.sorts.createSortInput(),
                },
            }),
            refreshListOnChanges: [this.filters.valueChanges, this.sorts.valueChanges],
        });
    }

    private getModuleType(__typename: 'MilktapModule' | 'VendingModule') {
        switch (__typename) {
            case 'MilktapModule':
                return 'Melktap';
            case 'VendingModule':
                return 'Agromaat';
        }
    }
}
