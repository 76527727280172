import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService, ModalService, NotificationService, SharedModule } from '@vendure/admin-ui/core';
import { BehaviorSubject, catchError, Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GET_PRODUCT_CELLS, SUBSCRIPTION_PRODUCT_CELL_STATE_UPDATED } from './product-cells-overview.graphql';
import {
    BulkOpenProductCellForProductCellsOverviewDocument,
    BulkUpdateProductCellProductVariantForProductCellsOverviewDocument,
    GetProductCellsQuery,
    ProductCellStateUpdated,
} from '../../generated-types';
import { GqlSubscriptionService } from '../../providers/gql-subscription-service';
import { ProductVariantSelectDialogComponent } from '../product-variant-select-dialog/product-variant-select-dialog.component';
import { DeliverType } from '../../ui-types';

type Item = GetProductCellsQuery['productCells']['items'][number];

type Machine = {
    id: number;
    items: Item[][];
};

type ProductCellsOverviewMode = 'home' | 'open' | 'edit';

@Component({
    selector: 'product-cells-overview',
    templateUrl: './product-cells-overview.component.html',
    styleUrls: ['./product-cells-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductCellsOverviewComponent implements OnInit, OnDestroy {
    private cellsSubject = new BehaviorSubject<Item[]>([]);
    private subscription: Subscription | null = null;
    private selectedMachineId = 1;
    isLoading = false;

    @Input() mode: ProductCellsOverviewMode = 'home';
    modeOptions = [
        {
            id: 'home',
            value: 'home',
            title: 'Bekijk voorraad',
            iconShape: 'view-cards',
            iconDirection: '',
        },
        {
            id: 'open',
            value: 'open',
            title: 'Openen',
            iconShape: 'login',
            iconDirection: '',
        },
        {
            id: 'edit',
            value: 'edit',
            title: 'Wijzig product',
            iconShape: 'pencil',
            iconDirection: '',
        },
    ];

    constructor(
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
        private readonly dataService: DataService,
        private readonly gqlSubscriptionService: GqlSubscriptionService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.dataService
            .query<GetProductCellsQuery>(GET_PRODUCT_CELLS)
            .refetchOnChannelChange()
            .mapStream(data => data.productCells.items)
            .pipe(
                catchError(() => of([])),
                tap(() => (this.isLoading = false)),
            )
            .subscribe(columns => {
                this.cellsSubject.next(columns);
            });

        this.subscription = this.gqlSubscriptionService
            .subscribe<{
                productCellStateUpdated: ProductCellStateUpdated;
            }>(SUBSCRIPTION_PRODUCT_CELL_STATE_UPDATED)
            .subscribe({
                next: data => {
                    if (data.productCellStateUpdated) {
                        this.handleCellStateUpdate(data.productCellStateUpdated);
                    }
                },
                error: err => {
                    console.error('Subscription error:', err);
                },
                complete: () => {
                    console.log('Subscription completed');
                },
            });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    get machines$(): Observable<Machine[]> {
        return this.cellsSubject.asObservable().pipe(
            map(cells => {
                const machines: (Machine & {
                    items: Item[];
                })[] = [];
                let lastCol: number | null = null;
                for (const item of cells) {
                    if (lastCol === null || lastCol > item.col) {
                        machines.push({ id: machines.length + 1, items: [] });
                    }
                    machines[machines.length - 1].items.push(item);
                    lastCol = item.col;
                }

                return machines.map(machine => ({
                    ...machine,
                    items: this.splitByColumns(machine.items),
                }));
            }),
        );
    }

    get selectedMachine$(): Observable<Machine> {
        return this.machines$.pipe(
            map(machines => machines.find(({ id }) => this.selectedMachineId === id)!),
        );
    }

    get selectedCells$(): Observable<Item[][]> {
        return this.selectedMachine$.pipe(map(machine => machine?.items || []));
    }

    selectMachine(machineId: number): void {
        this.selectedMachineId = machineId;
    }

    setMode(mode: ProductCellsOverviewMode): void {
        this.mode = mode;
    }

    update(item: Item) {
        if (this.mode === 'edit') {
            this.edit(item);
            return;
        }
        if (this.mode === 'open') {
            this.open(item);
            return;
        }
        console.error('[update] unknown mode:', this.mode);
    }

    private open(item: Item) {
        this.dataService
            .mutate(BulkOpenProductCellForProductCellsOverviewDocument, {
                input: {
                    ids: [item.id],
                },
            })
            .subscribe(
                result => {
                    if (result.bulkOpenProductCell.length !== 1) {
                        this.notificationService.error('common.notify-update-error', {
                            entity: 'Productvak',
                        });
                        return;
                    }

                    const updated = result.bulkOpenProductCell[0];
                    const updatedItems = this.cellsSubject.value.map(item => {
                        if (item.id === updated.id) {
                            return updated;
                        }
                        return item;
                    });
                    this.cellsSubject.next(updatedItems);
                    this.notificationService.success('common.notify-update-success', {
                        entity: 'Productvak',
                    });
                },
                () =>
                    this.notificationService.error('common.notify-update-error', {
                        entity: 'Productvak',
                    }),
            );
    }

    private edit(item: Item) {
        this.modalService
            .fromComponent(ProductVariantSelectDialogComponent, {
                locals: {
                    cancellable: true,
                    deliverDevice: DeliverType.VENDING,
                    excludeIds: [item.productVariant.id],
                    productCellType: item.type,
                    cooling: item.productModule.vending.cooling,
                },
            })
            .subscribe(ids => {
                if (ids) {
                    this.dataService
                        .mutate(BulkUpdateProductCellProductVariantForProductCellsOverviewDocument, {
                            input: {
                                ids: [item.id],
                                productVariantId: ids[0],
                            },
                        })
                        .subscribe(
                            result => {
                                if (
                                    result.bulkUpdateProductCellProductVariant[0].__typename !== 'ProductCell'
                                ) {
                                    this.notificationService.error('common.notify-update-error', {
                                        entity: 'AgroBox',
                                    });
                                    return;
                                }

                                const updated = result.bulkUpdateProductCellProductVariant[0];
                                const updatedItems = this.cellsSubject.value.map(item => {
                                    if (item.id === updated.id) {
                                        return updated;
                                    }
                                    return item;
                                });
                                this.cellsSubject.next(updatedItems);
                            },
                            () =>
                                this.notificationService.error('common.notify-update-error', {
                                    entity: 'AgroBox',
                                }),
                        );
                }
            });
    }

    private handleCellStateUpdate(updatedCell: Pick<Item, 'id' | 'state'>): void {
        const currentCells = this.cellsSubject.value;
        const updatedCells = currentCells.map(cell =>
            cell.id === updatedCell.id ? { ...cell, ...updatedCell } : cell,
        );
        this.cellsSubject.next(updatedCells);
    }

    private splitByColumns(items: Item[]): Item[][] {
        const grouped = items.reduce((acc: Record<number, Item[]>, item) => {
            const colValue = item!.col;
            if (!acc[colValue]) {
                acc[colValue] = [];
            }
            acc[colValue].push(item);
            return acc;
        }, {});

        return Object.values(grouped);
    }
}
