import { registerDashboardWidget } from '@vendure/admin-ui/core';

export default [
    registerDashboardWidget('monitoring', {
        title: 'Monitoring',
        supportedWidths: [12, 6],
        loadComponent: () =>
            import('./widgets/monitoring-widget/monitoring-widget.component').then(
                m => m.MonitoringWidgetComponent,
            ),
        requiresPermissions: ['SuperAdmin'],
    }),
];
